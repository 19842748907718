import { useState, useEffect } from "react"
// hooks
import useStorage from "./useStorage"
// util
import createLocalDatabase from "../util/createLocalDatabaseInstance"

// custom hook to remove related items from product
const useRelatedRemoveStorage = (databaseName = "products") => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store
  const [, , newProduct] = useStorage()
  const [productKey, setProductKey] = useState()
  const [relatedKey, setRelatedKey] = useState()
  const [checkDeletedKey, setCheckDeletedKey] = useState(false)
  const [error, setError] = useState(false)

  // removes the related item from product
  useEffect(() => {
    if (productKey && relatedKey && relatedKey !== 0) {
      localDatabase
        .getItem(`${productKey}`)
        .then((value) => {
          // value loaded from the database
          if (value != null) {
            let { relatedProducts, ...obj } = value
            newProduct({
              ...obj,
              relatedProducts: relatedProducts.filter(
                (item) => item !== relatedKey
              )
            })
            setCheckDeletedKey(true)
          }
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
    }
  }, [relatedKey, localDatabase]) // eslint-disable-line react-hooks/exhaustive-deps

  // trigger to remove
  const setter = (relatedProductID, productID) => {
    try {
      // Save state
      setProductKey(productID)
      setRelatedKey(relatedProductID)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }
  // return removed related item status as boolean, setter fuction, and error
  return [checkDeletedKey, setter, error]
}

export default useRelatedRemoveStorage