import React, { useContext } from "react"
import { Link as RouterLink } from "react-router-dom"
//hooks
import useStorageAll from "../hooks/useStorageAll"
// util
import { displayTwoDecimal } from "../util/numberFormatUtil"
//style components and mui
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import Link from "@material-ui/core/Link"
// context
import { CurrencyContext } from "../contexts/CurrencyContext"

//style
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  row-gap: 20px;
  margin: 20px 20px;
`

const ChildContainer = styled(Paper)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  padding: 20px;
`

const ChildAddContainer = styled(ChildContainer)`
  background: #3498db;
  color: white;
  &:hover {
    background: #2980b9;
  }
  &:active {
    background: #9b59b6;
  }
`

const Home = () => {
  // custom hook to fetch all products from database
  const [products] = useStorageAll("products")
  // currency context
  const [currencyState] = useContext(CurrencyContext)
  return (
    <Container className="home-container">
      <Link
        style={{ textDecoration: "none" }}
        component={RouterLink}
        to={`/product/new`}
      >
        <ChildAddContainer>
          <div>Add a new product</div>
        </ChildAddContainer>
      </Link>
      {products.map((item, i) => (
        <Link
          key={i}
          component={RouterLink}
          style={{ textDecoration: "none" }}
          to={`/products/${item.id}`}
        >
          <ChildContainer>
            <div>#{item.id}</div>
            <div>{item.name}</div>
            {currencyState === "USD" && (
              <div>USD {displayTwoDecimal(item.usd)}</div>
            )}
            {currencyState === "AUD" && (
              <div>AUD {displayTwoDecimal(item.aud)}</div>
            )}
            {currencyState === "CNY" && (
              <div>CNY {displayTwoDecimal(item.cny)}</div>
            )}
          </ChildContainer>
        </Link>
      ))}
    </Container>
  )
}

export default Home
