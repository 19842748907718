import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
// import reportWebVitals from './reportWebVitals';
// mudbath provided source files
import PRODUCTS_SOURCE from "./api/data/products.json"
// import EXCHANGE_SOURCE from "./api/data/exchange_rates.json"
//util
import { loadInitialData } from "./util/initialDataSource"

ReactDOM.render(<App />, document.getElementById("root"))

loadInitialData(PRODUCTS_SOURCE) // load product json data and onto localstorage
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
