import createLocalDatabase from "./createLocalDatabaseInstance"
import productModel from "./productModel"

// name of the database
const PRODUCT_DATABASE = process.env.REACT_APP_PRODUCT_DATABASE || "products"

// function to persist the starter data
export function loadInitialData(PRODUCTS_SOURCE) {
  if (localStorage.getItem("starterData") === null) {
    let localDatabase = createLocalDatabase(PRODUCT_DATABASE)
    try {
      PRODUCTS_SOURCE.forEach((item) => {
        localDatabase.setItem(
          `${item.id}`,
          productModel({
            id: item.id,
            name: item.name,
            description: item.description,
            currency: item.price.base,
            price: item.price.amount,
            relatedProducts: item.relatedProducts
          })
        )
      })
      localStorage.setItem("starterData", true)
    } catch (error) {
      console.log("Persisting starter data failed")
    }
  }
}
