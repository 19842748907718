import createLocalDatabase from "../util/createLocalDatabaseInstance"
import { useState, useEffect } from "react"

// custom hook to remove product item
const useDeleteStorage = (key = 0, databaseName = "products") => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store our key
  const [storedKey, setStoredKey] = useState(key)
  const [checkDeletedKey, setCheckDeletedKey] = useState(false)
  const [error, setError] = useState(false)

  // delete the product item from database if the key is updated
  useEffect(() => {
    if (storedKey && storedKey !== 0) {
      localDatabase
        .removeItem(`${storedKey}`)
        .then(() => {
          setCheckDeletedKey(true)
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
    }
  }, [storedKey, localDatabase])

  // save to storedKey and trigger useEffect.
  const setter = (value) => {
    try {
      // Save state
      setStoredKey(value)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }
  // return deleted item status as boolean, setter fuction, and error
  return [checkDeletedKey, setter, error]
}
export default useDeleteStorage
