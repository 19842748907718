import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
//components
import CurrencyMenu from "./CurrencyMenu"
//icons
import HomeIcon from "@material-ui/icons/Home"
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList"
import AddBoxIcon from "@material-ui/icons/AddBox"
//style components and mui
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import MenuIcon from "@material-ui/icons/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"

// header
const Header = () => {
  //menu toggle state
  const [menuBar, setMenuBar] = useState(false)
  //menu function to toggle
  const toggleDrawer = (toggle) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setMenuBar(toggle)
  }

  //menulist object (home, Product)
  const menuItems = [
    {
      name: "Home",
      icon: HomeIcon,
      href: "/"
    },
    {
      name: "Product",
      icon: FeaturedPlayListIcon,
      href: "/products"
    },
    {
      name: "New Product",
      icon: AddBoxIcon,
      href: "/product/new"
    }
  ]

  //menu listing
  const menuList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((menuItem, index) => (
          <ListItem
            component={RouterLink}
            to={menuItem.href}
            button
            key={index}
          >
            <ListItemIcon>
              {React.createElement(menuItem.icon, {
                color: "primary",
                fontSize: "small"
              })}
            </ListItemIcon>
            <Box pr={4}>
                {menuItem.name}
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <>
      {/*Header start*/}
      <AppBar elevation={1} position="static">
        <Toolbar>
          {/*Menubar*/}
          {/*Menubar button*/}
          <IconButton
            onClick={() => setMenuBar(true)}
            edge="start"
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          {/*Menubar list*/}
          <Drawer anchor="left" open={menuBar} onClose={toggleDrawer(false)}>
            {menuList()}
          </Drawer>

          {/*Logo*/}
          <Typography variant="h6">XYZ Clothing</Typography>
          <CurrencyMenu />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
