import React, { useContext, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
//context
import { CurrencyContext } from "../contexts/CurrencyContext"
//components
import RelatedProducts from "../components/RelatedProducts"
//hooks
import useCheckStorage from "../hooks/useCheckStorage"
import useStorage from "../hooks/useStorage"
import useDeleteStorage from "../hooks/useDeleteStorage"
// util
import { displayTwoDecimal } from "../util/numberFormatUtil"
//style components and material ui
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"
import AddBoxIcon from "@material-ui/icons/AddBox"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
//style
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  row-gap: 20px;
  margin: 20px 20px;
`
const ChildContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  padding: 20px;
`
const ProductContainer = styled(Paper)`
  background: #34495e;
  color: #ecf0f1;
`

const StyledButton = styled(Button)`
  margin-left: 12px;
  background-color: #eee;
`
const StyledFButton = styled(Button)`
  background-color: #eee;
`
// displays single product item
const Product = (props) => {
  // get id param
  const { id } = useParams()
  // history object to push uri
  const history = useHistory()
  // currency context reducer function
  const [currencyState] = useContext(CurrencyContext)
  // custom hook returns product from database
  const [product] = useStorage(id)
  // custom hook checks if product exists in database
  const [checkProduct] = useCheckStorage(id)
  // state for related items
  const [state, setState] = useState()
  // custom hook to delete product item from database
  const [remove, setRemove] = useDeleteStorage()
  // if item is deleted. redirect to all products page.
  useEffect(() => {
    if (remove) history.push(`/products`)
  }, [remove]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setState(product.relatedProducts)
  }, [product])

  return checkProduct ? (
    <Container>
      <div>
        <StyledFButton
          className="product-edit-button"
          variant="contained"
          color="default"
          endIcon={<EditIcon>Edit</EditIcon>}
          onClick={() => history.push(`/edit/${id}`)}
        >
          Edit
        </StyledFButton>
        <StyledButton
          className="product-new-button"
          variant="contained"
          color="default"
          endIcon={<AddBoxIcon>New</AddBoxIcon>}
          onClick={() => history.push(`/product/new`)}
        >
          New
        </StyledButton>
        <StyledButton
          className="product-delete-button"
          variant="contained"
          color="default"
          endIcon={<HighlightOffIcon>Delete</HighlightOffIcon>}
          onClick={() => setRemove(id)}
        >
          Delete
        </StyledButton>
      </div>
      <ProductContainer className="product">
        <ChildContainer>
          <div>#{product.id}</div>
          <div>{product.name}</div>
          {currencyState === "USD" && (
            <div>USD {displayTwoDecimal(product.usd)}</div>
          )}
          {currencyState === "AUD" && (
            <div>AUD {displayTwoDecimal(product.aud)}</div>
          )}
          {currencyState === "CNY" && (
            <div>CNY {displayTwoDecimal(product.cny)}</div>
          )}
        </ChildContainer>
        {product.description && (
          <ChildContainer>
            <div>Details: {product.description}</div>
          </ChildContainer>
        )}
      </ProductContainer>

      {state &&
        state.map((item, i) => (
          <RelatedProducts
            key={i}
            relatedProductID={item}
            productID={product.id}
          />
        ))}

      <StyledFButton
        variant="contained"
        color="default"
        className="add-related-products-button"
        endIcon={<AddCircleOutlineIcon>Add</AddCircleOutlineIcon>}
        onClick={() => history.push(`/products/${id}/related`)}
      >
        Add Related Product
      </StyledFButton>
    </Container>
  ) : (
    <h1>404</h1>
  )
}

export default Product
