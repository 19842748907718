import EXCHANGE_RATES from "../api/data/exchange_rates.json" //exchange rates source provided by mudbath
import { twoDecimalLimit } from "./numberFormatUtil" // round up two deicimal places

//selects slave function based on currency base value
export default function baseConvert(currency, obj) {
  if (currency === "AUD") {
    return convertFromAUD(obj)
  }
  if (currency === "USD") {
    return convertFromUSD(obj)
  }
  if (currency === "CNY") {
    return convertFromCNY(obj)
  }
}

// adds CNY and USD prices to the Product model
export function convertFromAUD(obj) {
  return {
    ...obj,
    aud: twoDecimalLimit(obj.price),
    cny: twoDecimalLimit(obj.price * EXCHANGE_RATES[0].rates.CNY),
    usd: twoDecimalLimit(obj.price * EXCHANGE_RATES[0].rates.USD)
  }
}

// adds CNY and AUD prices to the Product model
export function convertFromUSD(obj) {
  return {
    ...obj,
    usd: twoDecimalLimit(obj.price),
    cny: twoDecimalLimit(obj.price * EXCHANGE_RATES[0].rates.CNY),
    aud: twoDecimalLimit(obj.price * EXCHANGE_RATES[1].rates.AUD)
  }
}

//adds AUD and USD prices to the Product model
export function convertFromCNY(obj) {
  return {
    ...obj,
    cny: twoDecimalLimit(obj.price),
    aud: twoDecimalLimit(obj.price * EXCHANGE_RATES[1].rates.AUD),
    usd: twoDecimalLimit(obj.price * EXCHANGE_RATES[0].rates.USD)
  }
}

// converts USD price to CNY
// export function convertToCNYFromUSD(price) {
//   return (price * EXCHANGE_RATES[1].rates.CNY).toFixed(2)
// }

// converts USD price to AUD
// export function convertToAUDFromUSD(price) {
//   return (price * EXCHANGE_RATES[1].rates.AUD).toFixed(2)
// }
