import { useReducer, useCallback } from "react"
import {useLocalStorage} from "./useLocalStorage"
// init values
const LOCAL_STORAGE_KEY = "curreny"
const INITIAL_STATE = "USD"

// reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "AUD":
      return "AUD"
    case "USD":
      return "USD"
    case "CNY":
      return "CNY"
    default:
      return "AUD"
  }
}

// custom persistant reducer hook
export const useLocalStorageReducer = () => {
  // grab saved value from localStorage
  const [savedState, setSaveState] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    INITIAL_STATE
  )

  // syncs the `newState` to `localStorage` before
  // returning `newState`.
  const reducerLocalStorage = useCallback(
    (state, action) => {
      const newState = reducer(state, action)
      setSaveState(newState)
      return newState
    },
    [setSaveState]
  )

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, savedState)
}




