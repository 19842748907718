import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
//hook
import useStorageAll from "../hooks/useStorageAll"
import useStorage from "../hooks/useStorage"
import useRelatedAddStorage from "../hooks/useRelatedAddStorage"
//style & material ui
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
//style
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  row-gap: 20px;
  margin: 20px 20px;
`
const ChildContainer = styled(Paper)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  padding: 20px;
`
const StyledButton = styled(Button)`
  margin-left: 12px;
  background-color: #eee;
  margin: -20px;
  padding: 20px;
`
const StyledFButton = styled(Button)`
  background-color: #eee;
`
const AddRelatedProducts = () => {
  // get id param
  const { id } = useParams()
  // history router object
  const history = useHistory()
  // lists all products
  const [allProducts] = useStorageAll()
  // custom hook returns product from database
  const [product] = useStorage(id)
  // add related products to product items
  const [added, setAdd] = useRelatedAddStorage()
  // trigger to add related product
  const handleClick = (AddRelatedProductID) => {
    setAdd(AddRelatedProductID, id)
  }
  // returns to product page when new related product is added
  useEffect(() => {
    added && history.push(`/products/${id}`)
  }, [added]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <StyledFButton
        variant="contained"
        color="default"
        endIcon={<KeyboardBackspaceIcon>Back</KeyboardBackspaceIcon>}
        onClick={() => history.push(`/products/${id}`)}
      >
        Back
      </StyledFButton>
      {allProducts &&
        allProducts.map((item, i) =>
          product.relatedProducts
            ? !product.relatedProducts.includes(item.id) &&
              product.id !== item.id && (
                <React.Fragment key={i}>
                  <ChildContainer>
                    <div>#{item.id}</div>
                    <div>{item.name}</div>
                    <StyledButton
                      className="add-related-product-button"
                      variant="contained"
                      color="default"
                      endIcon={<AddCircleOutlineIcon>Add</AddCircleOutlineIcon>}
                      onClick={() => handleClick(item.id)}
                    >
                      Add
                    </StyledButton>
                  </ChildContainer>
                </React.Fragment>
              )
            : product.id !== item.id && (
                <React.Fragment key={i}>
                  <ChildContainer>
                    <div>#{item.id}</div>
                    <div>{item.name}</div>
                    <StyledButton
                      variant="contained"
                      color="default"
                      endIcon={
                        <AddCircleOutlineIcon>Delete</AddCircleOutlineIcon>
                      }
                      onClick={() => handleClick(item.id)}
                    >
                      Add
                    </StyledButton>
                  </ChildContainer>
                </React.Fragment>
              )
        )}
    </Container>
  )
}

export default AddRelatedProducts
