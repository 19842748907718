import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
//util
import productModel from "../util/productModel"
//Style
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
//validation
import { useFormik } from "formik"
import * as Yup from "yup"
// hooks
import useStorage from "../hooks/useStorage"
//style
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  row-gap: 20px;
  margin: 20px 20px;
`

const ChildContainer = styled(Paper)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  padding: 20px;
`
// similiar to add page. but disables ID property
const EditProduct = () => {
  // get the uri :id
  const { value } = useParams()
  // history object
  const history = useHistory()
  // custom hook to get database induvidual items, get new items
  const [product, , newProduct] = useStorage(value)

  // initial formik state
  const [state, setState] = useState(() => {
    return {
      id: value,
      name: "",
      description: "",
      currency: "",
      price: "",
      relatedProducts: []
    }
  })

  useEffect(() => {
    setState({
      id: value,
      name: product.name ? product.name : "",
      description: product.description ? product.description : "",
      currency: product.currency ? product.currency : "",
      price: product.price ? product.price : "",
      relatedProducts: product.relatedProducts ? product.relatedProducts : []
    })
  }, [product, value])

  // Yup object schema validation always use it before formik initializing
  const validationSchema = Yup.object({
    id: Yup.number("Enter a numeric ID"),
    name: Yup.string("Enter the name of the product")
      .min(3)
      .typeError("you must give a name more than 3 character")
      .required("Name is required"),
    description: Yup.string("Enter the product description").notRequired(),
    currency: Yup.string().required("Choose a base currency"),
    price: Yup.number("Enter the product price")
      .typeError("you must give enter the price")
      .required("price is required")
  })

  // formik hook initializing
  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      let keyProduct = productModel(values)
      newProduct(keyProduct)
      history.push(`/products/${keyProduct.id}`)
    }
  })

  return (
    <Container>
      <ChildContainer>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="product-id"
            fullWidth
            name="id"
            disabled
            label="Product ID"
            value={formik.values.id}
            onChange={formik.handleChange}
          />
          <TextField
            className="product-name"
            fullWidth
            name="name"
            label="Product Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            className="product-desc"
            fullWidth
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            className="product-price"
            fullWidth
            name="price"
            label="Price"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
          <RadioGroup
            className="product-currency"
            name="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
          >
            <FormControlLabel value="USD" control={<Radio />} label="USD" />
            <FormControlLabel value="AUD" control={<Radio />} label="AUD" />
            <FormControlLabel value="CNY" control={<Radio />} label="CNY" />
          </RadioGroup>
          <Button
            variant="contained"
            className="product-submit"
            color="primary"
            disableElevation
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </Button>
          {formik.isSubmitting && Boolean(formik.isSubmitting)}
        </form>
      </ChildContainer>
    </Container>
  )
}

export default EditProduct
