import React, { useContext } from "react"
//context
import { CurrencyContext } from "../contexts/CurrencyContext"
//style components
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
// hooks
import useStorage from "../hooks/useStorage"
import useRelatedRemoveStorage from "../hooks/useRelatedRemoveStorage"
// util
import { displayTwoDecimal } from "../util/numberFormatUtil"
//style
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  row-gap: 20px;
`

const ChildContainer = styled(Paper)`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 20px;
  padding: 20px;
`
// displays the related products of each item
const RelatedProducts = ({ relatedProductID, productID }) => {
  // product item
  const [product] = useStorage(relatedProductID)
  // `setRemove` removes related products from item and sets `remove` true
  const [remove, setRemove] = useRelatedRemoveStorage()
  // global currency context
  const [currencyState] = useContext(CurrencyContext)

  return product.id && !remove ? (
    <Container className="related-products">
      <ChildContainer>
        <div>#{product.id}</div>
        <div>{product.name}</div>
        <div>
          <RemoveCircleOutlineIcon className="remove-related-item"
            onClick={() => setRemove(relatedProductID, productID)}
          />
        </div>
        {currencyState === "USD" && (
          <div>USD {displayTwoDecimal(product.usd)}</div>
        )}
        {currencyState === "AUD" && (
          <div>AUD {displayTwoDecimal(product.aud)}</div>
        )}
        {currencyState === "CNY" && (
          <div>CNY {displayTwoDecimal(product.cny)}</div>
        )}
      </ChildContainer>
    </Container>
  ) : null
}

export default RelatedProducts
