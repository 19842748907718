import React, { useContext } from "react"
//style compoenents
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import styled from "styled-components"
// context
import { CurrencyContext } from "../contexts/CurrencyContext"
// Style
const StyledButton = styled(Button)`
  margin-left: 30px;
  background-color: #eee;
`

// currency menu button to switch currency. global context
const CurrencyMenu = () => {
  //context
  const [state, dispatch] = useContext(CurrencyContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleAUD = () => {
    dispatch({
      type: "AUD"
    })
    setAnchorEl(null)
  }
  const handleUSD = () => {
    dispatch({
      type: "USD"
    })
    setAnchorEl(null)
  }
  const handleCNY = () => {
    dispatch({
      type: "CNY"
    })
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledButton
        variant="contained"
        endIcon={<LocalAtmIcon>send</LocalAtmIcon>}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {state}
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUSD}>USD</MenuItem>
        <MenuItem onClick={handleAUD}>AUD</MenuItem>
        <MenuItem onClick={handleCNY}>CNY</MenuItem>
      </Menu>
    </div>
  )
}

export default CurrencyMenu
