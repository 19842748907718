import baseConvert from "./currencyUtil"

// checks and adds all types of currency values
export default function productModel(obj) {
  let check = baseConvert(obj.currency, obj)
  return relatedProductsCheck(check)
}
// adds related product property if it doesn't exists
function relatedProductsCheck(obj) {
  let checked = obj.hasOwnProperty("relatedProducts")
    ? obj
    : { ...obj, relatedProducts: [] }
  return checked
}
