import { useState, useEffect } from "react"
// util
import createLocalDatabase from "../util/createLocalDatabaseInstance"
//hook
import useStorage from "./useStorage"
// custom hook to add related items from product
const useRelatedAddStorage = (databaseName = "products") => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store
  const [, , newProduct] = useStorage()
  const [productKey, setProductKey] = useState()
  const [updatedRelatedProducts, setUpdatedRelatedProducts] = useState(false)
  const [newRelatedProduct, setNewRelatedProduct] = useState()
  const [error, setError] = useState(false)

  // add related item to product and update the database
  // eslint-disable-next-line
  useEffect(() => {
    if (
      productKey &&
      newRelatedProduct &&
      Array.isArray(newRelatedProduct) &&
      newRelatedProduct.length
    ) {
      localDatabase
        .getItem(`${productKey}`)
        .then((value) => {
          // value loaded from the database
          if (value != null) {
            let { relatedProducts, ...obj } = value
            relatedProducts &&
              !relatedProducts.includes(...newRelatedProduct) &&
              newProduct({
                ...obj,
                relatedProducts: relatedProducts
                  ? [...relatedProducts, ...newRelatedProduct]
                  : newRelatedProduct
              })
            setUpdatedRelatedProducts(true)
          }
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
    }
  }, [newRelatedProduct, localDatabase]) // eslint-disable-line react-hooks/exhaustive-deps

  // trigger to add
  const setter = (AddRelatedProductID, productID) => {
    try {
      // Save state
      setProductKey(productID)
      setNewRelatedProduct([AddRelatedProductID])
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }
  // return added related item status as boolean, setter fuction, and error
  return [updatedRelatedProducts, setter, error]
}

export default useRelatedAddStorage
