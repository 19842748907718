import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
//context
import { CurrencyProvider } from "./contexts/CurrencyContext"
//hook
import { useLocalStorageReducer } from "./hooks/useLocalStorageReducer"
// components
import Header from "./components/Header"
// pages
import Home from "./pages/Home"
import Product from "./pages/Product"
import AddProduct from "./pages/AddProduct"
import EditProduct from "./pages/EditProduct"
import AddRelatedProducts from './pages/AddRelatedProducts'
const App = () => {
  // currency reducer hook for currency context
  const [state, dispatch] = useLocalStorageReducer()
  return (
    <CurrencyProvider value={[state, dispatch]}>
      <Router>
        {/*header*/}
        <Header />
        {/*container*/}
        <Switch>
          {/*add related Products page*/}
          <Route exact path="/products/:id/related">
            <AddRelatedProducts />
          </Route>
          {/*Product page*/}
          <Route exact path="/products/:id">
            <Product />
          </Route>
          {/*edit Product page*/}
          <Route exact path="/edit/:value">
            <EditProduct />
          </Route>
          {/*new Product page*/}
          <Route exact path="/product/new">
            <AddProduct />
          </Route>
          {/*Home page ⚛️*/}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        {/*footer*/}
        {/*FOOTER SPACE*/}
      </Router>
    </CurrencyProvider>
  )
}

export default App
