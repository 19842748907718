import React, { createContext } from "react"

// currency context
export const CurrencyContext = createContext()

// context provider hoc
export const CurrencyProvider = ({ children, value }) => {
  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  )
}
