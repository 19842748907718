import { useState, useEffect } from "react"
// util
import createLocalDatabase from "../util/createLocalDatabaseInstance"
// Custom hook to persist data onto indexeddb
// Instance like database
const useStorage = (key = 0, databaseName = "products", initialValue = {}) => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store key
  const [storedKey, setStoredKey] = useState(key)
  // State to store value
  const [storedValue, setStoredValue] = useState(initialValue)
  // State to store error as boolean
  const [error, setError] = useState(false)

  useEffect(() => {
    if (storedKey && storedKey !== 0)
      storedValue && Object.keys(storedValue).length === 0
        ? localDatabase
            .getItem(`${storedKey}`)
            .then((value) => {
              // value loaded from the database
              if (value != null) setStoredValue(value)
            })
            .catch((err) => {
              console.log(err)
              setError(true)
            })
        : localDatabase.setItem(`${storedKey}`, storedValue).catch((err) => {
            console.log(err)
            setError(true)
          })
  }, [storedValue]) // eslint-disable-line react-hooks/exhaustive-deps

  // setter function that persists the new value to database.
  const setValue = (value) => {
    try {
      // same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  // setter function that persists the new value to database.
  const newValue = (value) => {
    try {
      // same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredKey(valueToStore.id)
      setStoredValue(valueToStore)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  return [storedValue, setValue, newValue, error]
}

export default useStorage
