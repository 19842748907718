import { useState, useEffect } from "react"
// util
import createLocalDatabase from "../util/createLocalDatabaseInstance"
// Custom hook to fetch all products from indexeddb
const useStorageAll = (databaseName = "products") => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store our value
  const [storedValue, setStoredValue] = useState([])

  useEffect(() => {
    // iterate all the items in database
    localDatabase
      .iterate(function (value, key) {
        // set key and value to storedValue
        if (!storedValue.includes(value))
          setStoredValue((obj) => [...obj, value])
      })
      .catch(function (err) {
        console.log(err)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [storedValue]
}

export default useStorageAll
