import { useState, useEffect } from "react"
// util
import createLocalDatabase from "../util/createLocalDatabaseInstance"
// custom hook to check if product exists in the local database
const useCheckStorage = (key = 0, databaseName = "products") => {
  // instanse of local database
  const [localDatabase] = useState(() => createLocalDatabase(databaseName))
  // State to store
  const [storedKey, setStoredKey] = useState(key)
  const [checkKey, setCheckKey] = useState(() => (storedKey ? true : false))
  const [error, setError] = useState(false)

  useEffect(() => {
    if (storedKey && storedKey !== 0) {
      localDatabase
        .getItem(`${storedKey}`)
        .then((value) => {
          if (value != null) {
            setCheckKey(true)
          } else {
            setCheckKey(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
    }
  }, [storedKey, localDatabase])

  // setter function.
  const setter = (value) => {
    try {
      // Save state
      setStoredKey(value)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  return [checkKey, setter, error]
}
export default useCheckStorage
